// Commonly used constants within the frontend application.

export const ULABEL_SUBTASK_IDS = { 
    PLANT_COUNT_QA: "plant_counting",
    ROW_QA: "row_classification",
    SUBIMAGE_BOUNDS: "subimage_bounds",
}

export const ULABEL_SUBTASK_DISPLAY_NAMES = {
    [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: "Plant Counting",
    [ULABEL_SUBTASK_IDS.ROW_QA]: "Row Classification",
    [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: "Subimage Bounds",
}

// Login Page Modes
export const LOGIN_MODES = {
    SIGN_IN: "Sign In",
    SIGN_UP: "Sign Up",
    RESET_PASSWORD: "Reset Password",
}

// User Groups
export const USER_GROUPS = {
    INTERNAL: "Internal",
    EXTERNAL: "External",
}

export const DEFAULT_USER = "Stand QA User";

export const EXTERNAL_USER_ALLOWED_ROUTE_NAMES = [
    "Login",
    "Home",
    "PPA QA",
    "RejectPPA",
]